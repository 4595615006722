.test{
    background: #282c33;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
    border-radius: 4px;
    overflow: hidden;
}

.test .title{
    padding: 4px;
    display: flex;
}

.test .title input{
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    background: transparent;
    border: none;
    color: white;
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    outline: none;
}

.result {
    display: flex;
    gap: 16px;
}

.result div:first-child{
    flex: 1;
    display: flex;
    gap: 16px;
}

.result button{
    padding: 0;
    height: 28px;
    width: 24px;
    font-size: 18px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
}

.result .primary{
    background: #00affc;
    color: #232429;
    font-weight: 600;
    font-size: 14px;
    padding: 4px 8px;
    display: flex;
}

.result .green{
    background: #00ff00;
    color: #232429;
    font-weight: 600;
    font-size: 14px;
    padding: 4px 8px;
    display: flex;
}

.result .red{
    background: #ff0000;
    color: #232429;
    font-weight: 600;
    font-size: 14px;
    padding: 4px 8px;
    display: flex;
}

.result .yellow{
    background: #ffff00;
    color: #232429;
    font-weight: 600;
    font-size: 14px;
    padding: 4px 8px;
    display: flex;
}

.result .orange{
    background: #ff8c00;
    color: #232429;
    font-weight: 600;
    font-size: 14px;
    padding: 4px 8px;
    display: flex;
}