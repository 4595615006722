.app{
  max-width: 900px;
  margin: 0 auto;
  width: calc(100vw - 32px);
}

.app h1{
  text-align: center;
  font-weight: 500;
}

.testlist{
  display: flex;
  gap: 32px;
  flex-direction: column;
}

button{
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background: #282c33;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

button:disabled{
  opacity: 0.5;
  cursor: not-allowed;
}

.actions{
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
}

.note{
  
}